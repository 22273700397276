import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  useToast,
  Text,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
  TabList,
  Input as InputChakra,
  Select,
  Table,
  Tbody,
  Tr,
  Td,
  Th,
  Thead,
} from '@chakra-ui/react';
import {
  FaSearch,
  FaPlus,
  FaArrowRight,
  FaSave,
  FaEdit,
  FaArrowLeft,
  FaCheckCircle,
  FaTimesCircle,
  FaEraser,
} from 'react-icons/fa';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { Input } from '../../components/Form/Input';
import Button from '../../components/button';
import ListaCarne from '../../components/list/carne';
import ListaRotaCarne from '../../components/list/rota-carne';
import RotaCarne from '../../components/rotaCarnes';

import * as rotaServices from '../../services/rota';
import * as rotaCarneServices from '../../services/rotaCarne';

import * as CarneServices from '../../services/carne';
import * as UsuarioServices from '../../services/usuario';

import SimpleList from '../../components/list/usuario';
import usuarioInterface from '../../interface/usuario';

import carneInterface from '../../interface/carne';
import rotaInterface from '../../interface/rota';

import empresaContext from '../../contexts/empresa';
import Alert from '../../components/alertDialog';
import { InputControled } from '../../components/Form/inputControled';

type rotaFormData = {
  empresaId: number;
  usuarioId: number;
  nomeRota: string;
};

interface Props {
  rotaProps: rotaInterface;
  handleCloseCreate(rota: rotaInterface): void;
}

const RotaCreate: React.FC<Props> = ({ rotaProps, handleCloseCreate }) => {
  const toast = useToast();
  const { empresa } = useContext(empresaContext);
  const [textSearch, setTextSearch] = useState('');
  const [typeSearch, setTypeSearch] = useState('');
  const [textSearchCobrador, setTextSearchCobrador] = useState('');
  const [textCarne, setTextCarne] = useState('');
  const [openListaSearch, setOpenListaSearch] = useState(false);
  const [openRotaCarne, setRotaCarne] = useState(false);
  const [open, setOpen] = useState(false);

  const [carne, setCarne] = useState<carneInterface>({} as carneInterface);
  const [rota, setRota] = useState<rotaInterface>({} as rotaInterface);
  const [listaCarne, setListaCarne] = useState<carneInterface[]>([]);
  const [listaRota, setListaRota] = useState<carneInterface[]>([]);
  const [dataView, setDataView] = useState(false);
  const [usuarioLista, setUsuarioLista] = useState<usuarioInterface[]>([]);
  const [usuario, setUsuario] = useState<usuarioInterface>(
    {} as usuarioInterface,
  );
  const { register, handleSubmit, setValue, getValues } = useForm();

  useEffect(() => {
    if (rotaProps.id !== undefined) {
      setRota(rotaProps);
      setValue('nomeRota', rotaProps.nomeRota);
      setTextSearchCobrador(rotaProps.usuario?.nome);
    }
  }, []);

  useEffect(() => {
    function SortByOrdem(a: carneInterface, b: carneInterface) {
      const aOrder = a.ordem;
      const bOrder = b.ordem;
      // eslint-disable-next-line no-nested-ternary
      return aOrder < bOrder ? -1 : aOrder > bOrder ? 1 : 0;
    }
    if (rotaProps.carnes) {
      const listaOrderBy = Array.from(rotaProps.carnes);
      listaOrderBy.sort(SortByOrdem);
      setListaCarne(listaOrderBy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchUsuario = async () => {
    if (textSearchCobrador) {
      if (empresa) {
        const response = await UsuarioServices.GetByName(
          empresa.id,
          textSearchCobrador,
        );
        if (response.success && response.resource) {
          await setUsuarioLista(JSON.parse(JSON.stringify(response.resource)));
          setOpenListaSearch(true);
        } else {
          toast({
            title: 'Consulta Erro.',
            description: response?.message,
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        }
      }
    } else {
      toast({
        title: 'Consulta Erro.',
        description: 'Digite nome ou  email para consultar',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleCloseListaCliente = async (value: usuarioInterface) => {
    setUsuario(value);
    setOpenListaSearch(false);
    setTextSearchCobrador(value.nome);
  };

  const handleSaveRota = async () => {
    const values = getValues();
    const novaRota = {
      empresaId: empresa?.id,
      usuarioId: usuario.id,
      nomeRota: values.nomeRota,
    };

    if (rota.id !== undefined) {
      const editRota = {
        id: rota.id,
        empresaId: empresa?.id,
        usuarioId: usuario.id,
        nomeRota: values.nomeRota,
      };
      if (editRota.nomeRota.length > 0) {
        const responseRota = await rotaServices.Update(editRota);
        if (responseRota.success && responseRota.resource) {
          toast({
            title: 'Rota Edição.',
            description: 'Rota Editada com sucesso!',
            status: 'success',
            duration: 2000,
            isClosable: true,
          });
          setRota(JSON.parse(JSON.stringify(responseRota.resource)));
        } else {
          toast({
            title: 'Erro.',
            description: responseRota.message,
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        }
      } else {
        toast({
          title: 'Erro.',
          description: 'Nome da rota é obrigatório',
          status: 'warning',
          duration: 2000,
          isClosable: true,
        });
      }
    } else if (novaRota.nomeRota.length > 0) {
      const responseRota = await rotaServices.Insert(novaRota);
      if (responseRota.success && responseRota.resource) {
        setRota(JSON.parse(JSON.stringify(responseRota.resource)));
        setRotaCarne(true);
      } else {
        toast({
          title: 'Erro.',
          description: responseRota.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: 'Erro.',
        description: 'Nome da rota é obrigatório',
        status: 'warning',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleEditParcela = () => {
    setRotaCarne(true);
  };

  const handleCloseRotaCarne = async () => {
    if (empresa) {
      const response = await rotaServices.GetById(rota.id, empresa.id);
      if (response.success && response.resource) {
        handleCloseCreate(JSON.parse(JSON.stringify(response.resource)));
      } else {
        toast({
          title: 'Consulta Erro.',
          description: response.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
        handleCloseCreate({} as rotaInterface);
      }
    }
  };

  function currencyFormatter(value: any) {
    if (!Number(value)) return 0;
    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
    return `${amount}`;
  }

  return (
    <Flex direction="column" h='full' w='full' >
      <Text
        casing="uppercase"
        fontSize="sm"
        color="gray.600"
        fontWeight="semibold"
        mb="4"
      >
        Cadastro de Rota
      </Text>

      <Flex as="form" onSubmit={handleSubmit(handleSaveRota)}>
        {openRotaCarne ? (
          <RotaCarne rota={rota} handleCloseRota={handleCloseRotaCarne} />
        ) : (
          <Grid
            h='full'
            w="100%"
            templateColumns="repeat(12, 1fr)"
            gap={5}
          >
            <GridItem
              colSpan={4}
              bg="white"
              p="3"
              h='full'
              borderRadius="sm"
              boxShadow="md"

            >
              <Input
                id="nomeRota"
                type="text"
                label="Nome da Rota"
                register={register}
              />

              <Flex w='full' gap='2' align='flex-end' mt='2'>
                <InputControled
                  label='Nome do Cobrador'
                  value={textSearchCobrador}
                  onChange={event => setTextSearchCobrador(event.target.value)}
                />
                <IconButton
                  aria-label="open-search"
                  icon={<Icon as={FaSearch} />}
                  fontSize="18"
                  variant="link"
                  mb='2'
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onClick={handleSearchUsuario}
                />
              </Flex>

              <Flex mt="12" justify="space-between" gap='4'>
                {rotaProps.id !== undefined ? (
                  <Button
                    bg="indigo.400"
                    color="white"
                    type="submit"
                    boxShadow="lg"
                    ml="0"
                    leftIcon={<Icon as={FaEdit} />}
                  >
                    Salvar
                  </Button>
                ) : (
                  <Button
                    bg="indigo.400"
                    color="white"
                    type="submit"
                    ml="0"
                    boxShadow="lg"
                    leftIcon={<Icon as={FaPlus} />}
                  >
                    Iniciar Rota
                  </Button>
                )}
                <Button
                  type="button"
                  onClick={() => handleCloseCreate(rota)}
                  bg="orange.400"
                  color="orange.800"
                  leftIcon={<Icon as={FaArrowLeft} />}
                  _hover={{
                    bg: 'orange.500',
                  }}
                >
                  Voltar
                </Button>
              </Flex>
            </GridItem>

            <GridItem
              colSpan={8}
              bg="white"
              boxShadow="md"
              borderRadius="sm"
              h={500}
              pl="5"
            >
              {rotaProps.id !== undefined ? (
                <>
                  <Table
                    width={650}
                    table-layout="fixed"
                    border-collapse="collapse"
                    size="sm"
                  >
                    <Thead display="block">
                      <Tr display="block">
                        <Th p={5} textAlign="left" w={162}>
                          CARNE
                        </Th>
                        <Th p={5} textAlign="left" w={162}>
                          CLIENTE
                        </Th>
                        <Th p={5} textAlign="left" w={162}>
                          PRODUTO
                        </Th>
                        <Th p={5} textAlign="left" w={162} isNumeric>
                          VALOR
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody display="block" width={650} overflow="auto" h={400}>
                      {listaCarne.map(carneItem => {
                        return (
                          <>
                            <Tr
                              key={carneItem.id}
                              display="block"
                              fontSize="xs"
                              color="gray.900"
                              _hover={{
                                bg: 'teal.100',
                              }}
                            >
                              <Td textAlign="left" w={162} fontSize="xs">
                                {carneItem.numeroReferencia}
                              </Td>
                              <Td textAlign="left" w={162} fontSize="xs">
                                {carneItem.cliente?.nomeCompleto}
                              </Td>
                              <Td textAlign="left" w={162} fontSize="xs">
                                {carneItem.descricaoProdutos}
                              </Td>
                              <Td
                                textAlign="left"
                                w={162}
                                fontSize="xs"
                                isNumeric
                              >
                                {currencyFormatter(carneItem.valorCompra)}
                              </Td>
                            </Tr>
                          </>
                        );
                      })}
                    </Tbody>
                  </Table>
                  <Button
                    bg="indigo.400"
                    color="white"
                    type="button"
                    boxShadow="lg"
                    w='44'
                    onClick={handleEditParcela}
                    leftIcon={<Icon as={FaEdit} />}
                  >
                    Editar
                  </Button>
                </>
              ) : (
                <></>
              )}
            </GridItem>
            {openListaSearch && (
              <GridItem colSpan={6}>
                <SimpleList
                  title="Consulta Cliente"
                  usuarios={usuarioLista}
                  isOpen
                  handleClose={handleCloseListaCliente}
                />
              </GridItem>
            )}
          </Grid>
        )}
      </Flex>
    </Flex>
  );
};

export default RotaCreate;
